import React from 'react';
import { Helmet } from 'react-helmet';

import { Translate } from 'src/components/Translate';
import { useTracking } from 'src/components/TrackingContext';

const metadata = {
  title: 'Consent Skipped | HDC for State Farm',
};

const Skip = () => {
  useTracking('sf_consent_skip_view');

  return (
    <>
      <Helmet title={metadata.title} />
      <section className="state-farm-container">
        <Translate resourceKey="state-farm.consent.skip.page" useHtml />
      </section>
    </>
  );
};

export default Skip;
